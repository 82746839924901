import store from "@/store"
import CheckBtn from '@/components/Input/CheckBtn';
import ComfirmBox from "@/components/ComfirmBox";

const listMethods = {
  change() {
    console.log(this.Secheduleinfo);
    console.log(this.EditScheduleInfoShow);
  },

  OpenCountry() {
    return store.state.Public.Country;
  },

  addSharePerson() {
    const email = this.EditScheduleInfoShow.input.personemail;
    const tmp = {
      email: this.EditScheduleInfoShow.input.personemail,
      uid: this.EditScheduleInfoShow.uid,
    }

    store.dispatch("schedule/checkEmailMember", tmp).then((data) => {
      this.EditScheduleInfoShow.searchAjax = true;
      if (data.data.state) {
        this.EditScheduleInfoShow.input.personemail = "";
        this.EditScheduleInfoShow.selectMember = data.data.result;
      } else {
        this.EditScheduleInfoShow.selectMember = [];
      };
    });
  },

  removePerson(item, index) {
    if (item.uid) {
      this.EditScheduleInfoShow.RemovePersonList.push(item);
    }
    this.EditScheduleInfoShow.PersonList.splice(index, 1);
  },

  EditScheduleBtn() {
    this.EditScheduleInfoShow.StartDate = this.$moment(this.EditScheduleInfoShow.StartDate)
      .format("YYYY-MM-DD");

    store.dispatch("schedule/EditScheduleBtn", {
      uid: this.EditScheduleInfoShow.uid,
      data: this.EditScheduleInfoShow
    }).then((data) => {

      Object.keys(data.data.result.Schedule).forEach((item) => {
        this.Secheduleinfo[item] = data.data.result.Schedule[item];
      });

      if (this.reback) {
        this.reback(data.data);
      }
    });
  },

  hidebox() {
    this.$emit("update:show", false);
  },

  selectShareMember(data) {
    this.EditScheduleInfoShow.PersonList.push(data);
    this.EditScheduleInfoShow.searchAjax = false;
  },

  delMyschedul(hide) {
    this.Confirm.show = true;
    this.Confirm.title = "刪除確認";
    this.Confirm.context = "確定要刪除行程？";

    this.Confirm.okfunc = () => {
      store.dispatch("schedule/deleteSchedule", this.EditScheduleInfoShow).then((data) => {
        hide()

        if (this.deleteMySchedule) {
          this.deleteMySchedule(data.data.state);
        } else {
          this.$router.replace({
            name: "index"
          });
        }
      });
    }
  },

  getSchedule(uid) {
    if (uid) {
      store.dispatch("schedule/getScheduleInfo", {
        uid: uid
      }).then((data) => {
        console.log(data);
        if (data.data.state) {
          this.Secheduleinfo = data.data.result.Schedule;
          ["Title", "StartDate", "TotalDay", "ShareType", "PersonList", "uid",
            "FromListLocation",
            "FirstList", "OpenRead"
          ].forEach((item, keys) => {
            let values = this.Secheduleinfo[item];
            if (item == "ShareType") {
              values = (values || "noshare");
            }

            console.log(values);
            this.EditScheduleInfoShow[item] = JSON.parse(JSON.stringify(values||""));
          })
        }
      });
    }
  }
}

const computed = {
  ShareMethodList: {
    get() {
      return store.state.schedule.ScheduleInfo.ShareList;
    }
  },
  ShareLevel: {
    get() {
      return store.state.schedule.edit.ShareLevel;
    }
  },
  UserInfo: {
    get() {
      return store.getters["user/getApiTokenreplace"];;
    }
  },
  isupdate() {
    const updateData = ["Title", "StartDate", "TotalDay", "ShareType", "PersonList", "uid",
      "FromListLocation", "OpenRead"

      // "FirstList"
    ].map((item, keys) => {

      let values = this.Secheduleinfo[item];
      let checkvalue = this.EditScheduleInfoShow[item];

      if (item == "ShareType") {
        values = (values || "noshare");
      }

      if (typeof (values) == "number") {
        values = values.toString();
      }

      if (typeof (checkvalue) == "number") {
        checkvalue = checkvalue.toString();
      }

      let oi = (checkvalue != values) ? values : "";

      return {
        field: item,
        value: oi
      }

    }).filter((item) => {
      let tmps = false;

      if ((typeof (item.value) == "object") || typeof (item.value) == "array") {
        tmps = !_.isEqual(item.value, this.EditScheduleInfoShow[item.field]);
      } else {
        tmps = !!item.value;
      }

      return tmps;
    });

    return updateData;
  }
}
export default {
  name: 'Editschedule',
  methods: listMethods,
  computed: computed,
  components: {
    CheckBtn,
    ComfirmBox
  },
  data() {
    this.getSchedule(this.Secheduleuid);
    return {
      QRcode: {
        Method: "public",
        level: "only_read"
      },
      openCheck: "1",
      QRcodeUrl: process.env.VUE_APP_TURL,
      lang: store.state.Public.lang.schedule,
      langPub: store.state.Public.lang,
      EditScheduleInfoShow: {
        show: false,
        OpenRead: "",
        Title: "",
        StartDate: "2019-06-06",
        TotalDay: 1,
        ShareType: "noshare",
        PersonList: [],
        input: {
          personemail: ""
        },
        selectMember: [],
        searchAjax: false,
        RemovePersonList: [],
        FromListLocation: [],
        FirstList: [],
      },
      Confirm: {
        show: false,
        title: "",
        context: "",
        okfunc: () => {}
      },
      Secheduleinfo: {}
    }
  },
  watch: {
    "show"(data) {
      this.EditScheduleInfoShow.show = data;

      if (data) {
        this.getSchedule(this.Secheduleuid);
      } else {
        this.EditScheduleInfoShow.input.personemail = "";
        this.EditScheduleInfoShow.searchAjax = false;
      }
    }
  },
  props: {
    Secheduleuid: String,
    show: {
      default: true,
      type: Boolean,

    },
    reback: Function,
    deleteMySchedule: Function
  },
};
