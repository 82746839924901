<template>
  <div>

    <b-modal v-model="EditScheduleInfoShow.show" title="Confirm.name" centered @cancel='hidebox()'
      @hide='hidebox()' :header-class="['py-2', 'bg-dagee']" :body-class="['p-0']"
      :footer-class="['p-0', 'bg-white']">

      <template v-slot:modal-header="{ close }">

        <div class="w-100 d-flex align-items-center justify-content-between">
          <div class="flex-fill">
            <b>修改行程</b>
          </div>
          <div @click="close()">
            <font-awesome-icon :icon="['fas', 'times']" size="lg" />
          </div>
        </div>
      </template>

      <template v-slot:default="{ hide }">
        <div class="w-100 p-2">
          <b-tabs content-class="border border-top-0 p-2" fill>
            <b-tab title="編輯" active>
              <div class="w-100">
                <div class="w-100 mb-2">
                  <!-- <label>{{lang.ScheduleInfo.title}}</label>
                  <input id="scheduleinfo-title" type="text" class="form-control input-sm px-2"
                    v-model='EditScheduleInfoShow.Title'> -->
                  <label>標題</label>
                  <b-input class="px-2" v-model="EditScheduleInfoShow.Title"></b-input>
                </div>

                <div class="d-flex align-items-center justify-content-between">
                  <div class="flex-fill">
                    <div class="mb-2">
                      <label class="mr-2"> 開始 </label>
                      <date-picker :placeholder="langPub.addSchedule.scheduleStart"
                        :first-day-of-week="1" :clearable="true" :shortcuts="false"
                        v-model="EditScheduleInfoShow.StartDate" />
                    </div>
                    <div>
                      <label class="mr-2"> 結束 </label>
                      <span>
                        {{EditScheduleInfoShow.StartDate | moment("add", (EditScheduleInfoShow.TotalDay-1)+" days") | moment("YYYY-MM-DD")}}
                      </span>
                    </div>
                  </div>
                  <div>
                    <span>共 {{EditScheduleInfoShow.TotalDay}} 天</span>
                  </div>

                  <!-- <div class="flex-fill" style="width:100px">
                    <label for="scheduleinfo-title">
                      {{langPub.addSchedule.scheduleStart}}
                    </label>
                    <div class="w-100 align-self-center">
                      <date-picker class="w-100" :first-day-of-week="1"
                        :placeholder='langPub.addSchedule.scheduleStart' :clearable='true'
                        :shortcuts='false' v-model="EditScheduleInfoShow.StartDate" />
                    </div>
                  </div>

                  <div class="flex-fill px-2 text-center">
                    <label for="scheduleinfo-title">
                      {{langPub.addSchedule.scheduleTotalDay}}
                    </label>
                    <div class="w-100 align-self-center">
                      共 {{EditScheduleInfoShow.TotalDay}} 天
                    </div>
                  </div>

                  <div class="flex-fill" style="width:100px">
                    <label for="scheduleinfo-title">
                      {{langPub.addSchedule.scheduleEnd}}
                    </label>
                    <div class="w-100 align-self-center">
                      {{EditScheduleInfoShow.StartDate | moment("add", (EditScheduleInfoShow.TotalDay-1)+" days") | moment("YYYY-MM-DD")}}
                    </div>
                  </div> -->
                </div>
              </div>
            </b-tab>

            <b-tab :title="lang.scheduleShareTab">
              <div class='clearfix'>
                <div class="d-flex flex-column align-items-left">

                  <div class="flex-fill">
                    <!-- <CheckBtn title='公開' type='radio' :tvalue='"1"'
                    :mod.sync='EditScheduleInfoShow.OpenRead' /> -->
                    <div class="w-100">
                      <b-form-checkbox v-model="EditScheduleInfoShow.OpenRead" value="1"
                        unchecked-value="0">
                        公開瀏覽行程
                      </b-form-checkbox>
                    </div>
                    <div class="w-100">
                      <small class="text-danger">
                        <ul class="m-0">
                          <li>
                            勾選後可以將行程分享給他人瀏覽
                          </li>
                        </ul>
                      </small>
                    </div>
                  </div>

                  <div class="flex-fill mt-2">
                    <!-- <CheckBtn v-for="(item, key) in ShareMethodList" :key="'btn__'+key"
                    :title="item.name" class='flex-fill' inputtype='radio' type='radio'
                    :tvalue="item.value" :mod.sync="EditScheduleInfoShow.ShareType" /> -->
                    <div class="d-flex align-items-center">
                      <b-form-group class="m-0">
                        <b-form-radio-group v-model="EditScheduleInfoShow.ShareType">
                          <b-form-radio v-for="(item, key) in ShareMethodList" :key="'btn__'+key"
                            :value="item.value">{{item.name}}
                          </b-form-radio>
                          <!-- <b-form-radio value="B">Option B
                      </b-form-radio> -->
                        </b-form-radio-group>
                      </b-form-group>
                    </div>
                    <div class="w-100">
                      <small class="text-danger">
                        <ul class="m-0">
                          <li>
                          </li>
                        </ul>
                      </small>
                    </div>
                  </div>

                </div>
              </div>

              <div class="clearfix" v-if='EditScheduleInfoShow.ShareType=="share"'>
                <b-input-group prepend="Email" class="mt-3" size='sm'>
                  <b-form-input v-model='EditScheduleInfoShow.input.personemail'
                    class='pms-searchAjax-Input'>
                  </b-form-input>

                  <b-input-group-append>
                    <b-button variant="info" @click='addSharePerson()'>
                      <font-awesome-icon :icon="['fas', 'search']" />
                    </b-button>
                  </b-input-group-append>

                  <div class='pms-searchAjax-list' v-if='EditScheduleInfoShow.searchAjax'>
                    <div class="card border">
                      <ul class="list-group list-group-flush">
                        <div class="card-header p-0">
                          <div class="d-flex justify-content-between p-2 cur-print"
                            @click="EditScheduleInfoShow.searchAjax=false">
                            <div class="flex-fill">
                              搜尋結果
                            </div>
                            <font-awesome-icon :icon="['fas', 'times']" size="lg" />
                          </div>
                        </div>

                        <li class="list-group-item cur-print pms-group-item-hover"
                          v-for='(i, index) in EditScheduleInfoShow.selectMember'
                          :key="'selmember__'+index" @click='selectShareMember(i)'>
                          <font-awesome-icon class="mr-2" :icon="['fas', 'user']" />
                          <span>{{i.name}}
                            <b class="ml-2">
                              {{i.email}}
                            </b>
                          </span>
                        </li>

                        <li class='list-group-item'
                          v-if="(EditScheduleInfoShow.selectMember.length==0)">
                          查無相關的會員
                        </li>
                      </ul>
                    </div>
                  </div>
                </b-input-group>

                <div class="clearfix mt-2">
                  <div class="d-flex flex-wrap">
                    <div class="border p-1 flex-fill"
                      v-for='(i, index) in EditScheduleInfoShow.PersonList' :key="'member__'+index">
                      <div class="d-flex align-items-center">

                        <span @click="removePerson(i,index)" class="text-danger cur-print mr-2">
                          <font-awesome-icon :icon="['fas', 'trash-alt']" size="lg" />
                        </span>

                        <span class="flex-fill">{{i.name}}
                          <b>
                            {{i.email}}
                          </b>
                        </span>

                        <div>
                          <select class='form-control' v-model='i.method' @change="change()">
                            <option v-for='(item, index) in ShareLevel' :value="item.value"
                              :key="'share__'+index">
                              {{lang.shareMethod[item.name]}}
                            </option>
                          </select>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab title="Share Qrcode"
              v-if='Secheduleinfo.OpenRead || Secheduleinfo.ShareType=="share"'>

              <b-alert variant="warning" v-if='isupdate.length>0' :show='isupdate.length>0'>
                {{lang.text.alerttextfornoUpdate}}
                <button class='btn btn-warning btn-sm'
                  @click="EditScheduleBtn()">{{lang.text.checksave}}</button>
              </b-alert>

              <b-tabs content-class="mt-3" fill nav-class='pms-tabs'>
                <b-tab :title="lang.QRcode['public']" :active='QRcode.Method=="public"'
                  v-if='Secheduleinfo.OpenRead' @click='QRcode.Method="public"'>
                  <div class="d-flex text-center flex-wrap" v-if='QRcode.Method=="public"'>
                    <qrcode :value="QRcodeUrl+'/Edit/'+Secheduleinfo.uid"
                      :options="{ width: '300' }" class='m-auto'></qrcode>
                  </div>
                  <!-- {{QRcodeUrl+'/QRcode/'+QRcode.level+'/'+Secheduleinfo.uid+'/'+UserInfo}} -->
                </b-tab>
                <b-tab :title="lang.QRcode['share']" :active='QRcode.Method=="share"'
                  v-if='Secheduleinfo.ShareType=="share"' @click='QRcode.Method="share"'>
                  <div class="d-flex">
                    <b-form-group class="w-100 m-0">
                      <b-form-radio-group class="d-flex justify-content-around"
                        v-model="QRcode.level">
                        <b-form-radio v-for='item in ShareLevel' :value="item.value">
                          {{lang.shareMethod[item.name]}}
                        </b-form-radio>
                        <!-- <b-form-radio value="B">Option B
                      </b-form-radio> -->
                      </b-form-radio-group>
                    </b-form-group>

                    <!-- <div v-for='item in ShareLevel'>
                    <CheckBtn :title='lang.shareMethod[item.name]' class='flex-fill'
                      inputtype='radio' type='radio' :tvalue='item.value'
                      :mod.sync='QRcode.level' />
                  </div> -->
                  </div>
                  <div class="d-flex text-center flex-wrap">
                    <qrcode
                      :value="QRcodeUrl+'/QRcode/'+QRcode.level+'/'+Secheduleinfo.uid+'/'+UserInfo"
                      :options="{ width: '300' }" class='m-auto'></qrcode>
                    <!-- {{QRcodeUrl+'/QRcode/'+QRcode.level+'/'+Secheduleinfo.uid+'/'+UserInfo}} -->
                  </div>
                </b-tab>
              </b-tabs>
            </b-tab>
          </b-tabs>
        </div>
      </template>

      <template v-slot:modal-footer="{ ok, cancel, hide }" class="w-100">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div class="flex-fill d-flex align-items-center justify-content-around">
            <button class="btn btn-sm text-success px-4" @click="EditScheduleBtn()" :disabled="!(isupdate.length > 0)">
              <font-awesome-icon :icon="['fas', 'check']" size="lg" />
            </button>

            <button class="btn btn-sm text-danger px-4" @click="cancel()">
              <font-awesome-icon :icon="['fas', 'times']" size="lg" />
            </button>
          </div>

          <button class="btn btn-sm text-danger" @click="delMyschedul(hide)"
            v-if="Secheduleinfo.Edit">
            <font-awesome-icon :icon="['fas', 'trash-alt']" size="lg" />
          </button>
        </div>
      </template>
    </b-modal>

    <ComfirmBox :show.sync='Confirm.show' :context='Confirm.context' :title='Confirm.title'
      :okfunc='Confirm.okfunc'>
    </ComfirmBox>
  </div>
</template>

<script>
  import ctrl from "./js/EditSchedule";
  export default ctrl;

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../assets/scss/variables';

  .pms-group-item-hover {
    &:hover {
      background: $dagee-color;
    }
  }

  .pms-searchAjax-list {
    position: absolute;
    width: 100%;
    margin-top: 2rem;
    background: #fff;
    z-index: 100;
  }

</style>
